import React, { useState, useEffect, useCallback } from 'react';

import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Figure
} from 'react-bootstrap';
import InfiniteScroll from "react-infinite-scroll-component";
import Discord from "./Discord";
import axios from "axios";
import { Tweet } from 'react-twitter-widgets'




const CONTROLLER = require('../controllers/nftAssetsController.js');

const Nftop = () => {
  const[threads, setThreads] = useState([]);

  useEffect(() => {
    // set placeholder trending NFTs for now
    initThreads();
  }, []);


  const initThreads = () => {
    const temp = [
      {id: "1439332605263032327"},
      {id: "1440169148206645257"},
      {id: "1440276738857000964"},
      {id: "1440303850842300431"},
      {id: "1440400852385107975"},
      {id: "1432802983528448000"},
      {id: "1433587285035913217"},
      {id: "1439289012376592385"},
    ];
    setThreads(temp);
  }

  // const getAssets = () => {
  //   if(ethAddr.trim().length == 0) {
  //     alert("Enter your eth address to view and select your NFTs.");
  //     return;
  //   }
  //   const callback = (res) => {setAssets(res.data.assets)};
  //   const callbackOnError = (error) => {alert(error.message);};
  //   CONTROLLER.getAssets(ethAddr, callback, callbackOnError);
  // }

  return (
    <Container style={{marginTop: "25px"}}>
      <Row>
        <Col style={{textAlign: "center"}}>
          <h1 style={{color: "purple", fontWeight:"bold", fontSize: "300%"}}> Find Twitter threads related to NFTs </h1>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col xs={2}></Col>

        {/* Eth address + NFT select */}
        <Col md={8} style={{marginBottom: "20px"}}>
          <Row>
            {threads.map((thread, index) => {
              return (
                <Col xs={4}>
                  <Tweet key={index} tweetId={thread.id} options={{ width: "300" }} />
                </Col>
              );
            })}
          </Row>
        </Col>

        <Col xs={2}></Col>
        {/* Trending NFTops */}
        {/*
        <Col md={4}>
          <Row style={{backgroundColor: "#F2F2F2", borderRadius: "25px"}}>
            <Col>
              <Row>
                <Col>
                  <h5 style={{marginTop: "15px"}}> 📈 Trending NFTops </h5>
                </Col>
              </Row>
              <br/>
              <Row>
                {trending.map((nft, index) => {
                  return (
                    <Col xs={12} style={{marginBottom: "20px", textAlign: "center"}}>
                      <img src={nft.img} style={{width: "80%", float: "center"}}/>
                      <div> {nft.name} </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Col>
        */}
      </Row>
      <br/>
      <br/>
      <Discord />
    </Container>
  );
}

export default Nftop;
