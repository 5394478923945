import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import Nftop from "./views/Nftop.js";
import NftopNavbar from "./views/NftopNavbar.js";
import { Mixpanel } from "./util/Mixpanel";

function App() {
  useEffect(() => {
    Mixpanel.track("NFThreads visited");
  }, []);

  return (
    <body>
      <NftopNavbar />
      <Nftop />
    </body>
  );
}

export default App;
